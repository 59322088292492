import React from "react"

import { withHtml } from "@components/logic"
import { Layout } from "@components/layout"
import { BlogHero, Recommendations, CommentSection } from "@components/blog"
import { Breadcrumbs } from "@components/shared"
import { Tags } from "@components/theme"

import { post } from "./styles/post.module.scss"

const Content = withHtml("article")

const Post = ({ pageContext }) => {
  const { page, posts } = pageContext
  const { categories } = page
  const [mainCategory] = categories.nodes

  let selectedLanguage = "/pl"
  if(window !== "undefined"){
    selectedLanguage = localStorage.getItem("language")
  }

  const { translations } = page
  const translationUrls = {
    pl:`/pl/blog/${translations[0].slug}/`,
    en:`/en/blog/${translations[0].slug}/`
  }
  return (
    <Layout {...page} translationUrl={translationUrls}>
      <BlogHero
        title={page.title}
        // featuredImage={page.node.featuredImage.sourceUrl}
      />
      <Breadcrumbs
        elements={[
          {
            label: "Blog",
            url: `${selectedLanguage}/blog/`,
          },
          {
            label: mainCategory.name,
            url: `${selectedLanguage}/blog/categories/${mainCategory.slug}`,
          },
          {
            label: page.title,
            url: `${selectedLanguage}/blog/${page.slug}/`,
          },
        ]}
      />
      <Tags tags={page.tags.nodes} tagsUrl="/tags" />
      <Content className={post}>{page.content}</Content>
      <Recommendations currentPost={page.slug} posts={posts} />
      {/* <CommentSection comments={page.comments} /> */}
    </Layout>
  )
}

export default Post

// import React from "react"
// import moment from "moment"
// import { Layout, Parallax, NewsletterSection } from "@components/layout"
// import { PostItem, CommentSection } from "@components/blog"
// import { withHtml } from "@components/logic"
// import { Tags, DirectionArrows } from "@components/theme"
// import { Breadcrumbs, AnimatedSection } from "@components/shared"
// import bottomIMG from "./img/code.jpg"
// import icon from "./img/open-book2.png"

// import {
//   post__wrapper,
//   post__header,
//   post__content,
//   post__wave,
//   post__icon,
//   icon__wrap,
//   post__bottomimg,
// } from "./styles/post.module.scss"

// const reduceNodes = (withNodes) => {
//   const { nodes } = withNodes
//   return nodes.reduce((acc, curr) => {
//     if (acc.length !== 0) {
//       return `${acc}, ${curr.name}`
//     }
//     return curr.name
//   }, "")
// }

// const Title = withHtml("h1")
// const Content = withHtml("article")
// const linkModifier = ({ slug }) => `/blog/${slug}/`

// const Post = ({ pageContext }) => {
//   const { page, nextPost, prevPost } = pageContext
//   const { categories, tags } = page
//   const [mainCategory] = categories.nodes

//   const Wave = () => {
//     return (
//       <div className={post__wave}>
//         <svg viewBox="0 0 1200 600">
//           <path
//             d="M 10 380 Q 60 600 420 520 Q 895 375 1080 445 Q 1190 480 1200 595 L 0 600 A 50 50 0 1 1 0 320 "
//             transform="translate(0 0)"
//             fill="#fff"
//           />
//         </svg>
//       </div>
//     )
//   }

//   const Icon = () => {
//     return (
//       <div className={post__icon}>
//         <img src={icon} alt="" />
//       </div>
//     )
//   }

//   return (
//     <Layout
//       seo={pageContext.page.seo}
//       siteMetaData={pageContext.page.siteMetaData}
//     >
//       <div className={post__wrapper}>
//         <Parallax height={500} image={page.featuredImage}>
//           <Title className={post__header}>{page.title}</Title>
//           <h5>Data: {moment(page.date).format("DD / MM / YYYY")}</h5>
//           <Wave />
//         </Parallax>
//         <AnimatedSection>
//           <Icon className={icon__wrap} />
//         </AnimatedSection>
//         <Breadcrumbs
//           elements={[
//             {
//               label: "Blog",
//               url: `/blog/`,
//             },
//             {
//               label: mainCategory.name,
//               url: `/blog/categories/${mainCategory.slug}`,
//             },
//             {
//               label: page.title,
//               url: `/blog/${page.slug}/`,
//             },
//           ]}
//         />

//         <Tags tags={tags.nodes} tagsUrl={"/tags"} />

//         <Content className={post__content}>{page.content}</Content>

//         <div className={post__bottomimg}>
//           <svg viewBox="0 0 1920 1280">
//             <path
//               d="M.4,544.87s180.79-339.65,909.88-283.3S1919.6,0,1919.6,0H.4Z"
//               transform="translate(-0.4)"
//               fill="#fff"
//             />
//             <path
//               d="M.4,1280c8.69-21.39,377.93-459.65,1175.86-401.74S1919.6,748.35,1919.6,748.35V1280Z"
//               transform="translate(-0.4)"
//               fill="#fff"
//             />
//           </svg>

//           <img src={bottomIMG} alt="" />
//         </div>
//         <DirectionArrows
//           prevItem={prevPost}
//           nextItem={nextPost}
//           linkModifier={linkModifier}
//         />

//         <CommentSection post={page} />
//         {/*

// 			<h4>prevPost </h4>

// 			<PostItem {...prevPost}/>
// 			<h4>nextPost</h4>
// 			<PostItem {...nextPost}/>

// 			<NewsletterSection />
// 			*/}
//       </div>
//     </Layout>
//   )
// }

// export default Post
